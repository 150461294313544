import React from 'react';
import { Typography, Box, styled } from '@mui/material';
import ComdirectPicture from '../images/comdirect.svg';
import { PARTNER_BANKS_BASE } from '../../../../src/helpers/urlConstants';
import { BankOverviewCard } from '../BankOverviewCard';

interface ComdirectCardProps {
  className?: string;
  urlPrefix?: string;
}

const PictureContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 8.5),
  display: 'flex',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 5),
  },
}));

export const ComdirectCard = ({ urlPrefix = PARTNER_BANKS_BASE }: ComdirectCardProps) => {
  return (
    <BankOverviewCard
      url={`${urlPrefix}/comdirect`}
      rank={5}
      picture={
        <PictureContainer>
          <ComdirectPicture width="128" height="auto" />
        </PictureContainer>
      }
      depotFeeComponent={
        <Typography variant="subtitle2" color="common.N400">
          0€
        </Typography>
      }
      refundPortfolioCommissionComponent={
        <Typography variant="subtitle2" color="common.N800">
          100%
        </Typography>
      }
      discountOneTimeCommissionComponent={
        <Typography variant="subtitle2" color="common.N800">
          100%
        </Typography>
      }
      averageCashbackComponent={
        <Typography variant="subtitle2" color="common.G500" fontWeight={600}>
          0.56%
        </Typography>
      }
      availableFundsComponent={
        <Typography variant="subtitle2" color="common.N800">
          24.000+
        </Typography>
      }
      savingsPlanPriceComponent={
        <Typography variant="subtitle2" color="common.N800">
          0,95€
        </Typography>
      }
      isSelected
    />
  );
};
