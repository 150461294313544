import {
  DetailCalculatorWithLocalStorage,
  DetailCalculatorWithLocalStorageProps as DetailCalculatorWithLocalStorageOriginalProps,
  CalculatorState,
  OldDetailCalculatorWithLocalStorage,
} from '@rentablo/cashback-calculator';
import { Ref } from 'react';

export type DetailCalculatorWithLocalStorageProps =
  DetailCalculatorWithLocalStorageOriginalProps & {
    calculatorRef?: Ref<CalculatorState>;
    old?: boolean;
  };

const DetailCalculatorWithLocalStorageWrapper = ({
  old,
  calculatorRef,
  ...props
}: DetailCalculatorWithLocalStorageProps) => {
  return old ? (
    <OldDetailCalculatorWithLocalStorage {...props} />
  ) : (
    <DetailCalculatorWithLocalStorage ref={calculatorRef} {...props} />
  );
};

export default DetailCalculatorWithLocalStorageWrapper;
