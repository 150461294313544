import React, { ReactNode } from 'react';
import {
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  Button,
  styled,
  Rating,
  Grid,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import StarIcon from '../../../public/icons/star.svg';
import StarEmptyIcon from '../../../public/icons/star-empty.svg';
import StarsSparkle from '../../../public/icons/stars-sparkle.svg';
import { Cell } from './Cell';

const Card = styled(MuiCard, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected?: boolean }>(({ theme, isSelected }) => ({
  backgroundColor: 'white',
  boxShadow: 'none',
  paddingTop: isSelected ? '0' : '30px',
  border: isSelected ? `2px solid ${theme.palette.tertiary.main}` : 'none',
  borderTop: !isSelected ? '2px solid transparent' : `2px solid ${theme.palette.tertiary.main}`,
  borderRadius: isSelected ? '8px' : '0',
  marginTop: isSelected ? theme.spacing(1.34) : 0,

  [theme.breakpoints.down('sm')]: {
    paddingTop: isSelected ? '0' : '22px',
    marginTop: isSelected ? theme.spacing(0.34) : 0,
  },
}));

const CardMedia = styled(MuiCardMedia)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '86px',

  [theme.breakpoints.down('sm')]: {
    height: '50px',
  },
}));

const CellContainer = styled(Grid)(({ theme }) => ({
  '& .MuiTypography-root': {
    fontSize: '16px',
  },
  '& .MuiGrid-item:nth-of-type(even)': {
    backgroundColor: theme.palette.background.neutralLight,
  },

  '& .MuiGrid-item:last-of-type': {
    backgroundColor: 'white',
  },

  [theme.breakpoints.down('sm')]: {
    '& .MuiTypography-root': {
      fontSize: '12px',
      lineHeight: '22px',
    },
  },
}));

const TestWinnerBadge = styled('span')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.tertiary.main,
  color: 'white',
  borderRadius: '3px 3px 0 0',
  width: '100%',
  padding: '4px 6px',
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: '14px',

  '& svg': {
    marginRight: theme.spacing(),
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
    lineHeight: '14px',
  },
}));

interface Props {
  picture: ReactNode;
  rank: number;
  className?: string;
  url: string;
  depotFeeComponent: ReactNode;
  refundPortfolioCommissionComponent: ReactNode;
  discountOneTimeCommissionComponent: ReactNode;
  availableFundsComponent: ReactNode;
  savingsPlanPriceComponent: ReactNode;
  averageCashbackComponent: ReactNode;
  isSelected?: boolean;
  headerTooltip?: ReactNode;
}

export const BankOverviewCard = ({
  picture,
  rank,
  url,
  depotFeeComponent,
  refundPortfolioCommissionComponent,
  discountOneTimeCommissionComponent,
  availableFundsComponent,
  savingsPlanPriceComponent,
  averageCashbackComponent,
  isSelected,
  headerTooltip,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isLessSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Card isSelected={isSelected}>
      {isSelected && (
        <TestWinnerBadge>
          <StarsSparkle width={isLessSm ? '11px' : '16px'} height={isLessSm ? '11px' : '16px'} />
          {t('bankOverview.testWinner')}
        </TestWinnerBadge>
      )}
      <CardMedia>
        <a href={url}>{picture}</a>
        {!isLessSm && headerTooltip}
      </CardMedia>
      <CellContainer flexDirection="column" container>
        <Cell justifyContent="center">{depotFeeComponent}</Cell>
        <Cell justifyContent="center">{refundPortfolioCommissionComponent}</Cell>
        <Cell justifyContent="center">{discountOneTimeCommissionComponent}</Cell>
        <Cell justifyContent="center">{averageCashbackComponent}</Cell>
        <Cell justifyContent="center">{availableFundsComponent}</Cell>
        <Cell justifyContent="center">{savingsPlanPriceComponent}</Cell>
        <Cell justifyContent="center" isSmall>
          <Rating
            value={rank}
            precision={0.1}
            readOnly
            size="small"
            icon={
              <StarIcon width={isLessSm ? '13px' : '20px'} height={isLessSm ? '13px' : '20px'} />
            }
            emptyIcon={
              <StarEmptyIcon
                width={isLessSm ? '13px' : '20px'}
                height={isLessSm ? '13px' : '20px'}
              />
            }
          />
        </Cell>
        <Cell justifyContent="center" isSmall>
          <Button
            size="small"
            href={url}
            sx={{
              color: theme.palette.tertiary.main,
              fontWeight: 600,
              fontSize: isLessSm ? '12px' : '14px',
            }}
          >
            {t('bankOverview.card.more')}
          </Button>
        </Cell>
      </CellContainer>
    </Card>
  );
};
