import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PARTNER_BANKS_BASE } from '../../../../src/helpers/urlConstants';
import { BankOverviewCard } from '../BankOverviewCard';
import FondsdepotLogo from '../images/fondsdepot.svg';

interface FondsdepotBankCardProps {
  className?: string;
  urlPrefix?: string;
}

const PictureContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 8),

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 5),
  },
}));

export const FondsdepotBankCard = ({
  className,
  urlPrefix = PARTNER_BANKS_BASE,
}: FondsdepotBankCardProps) => {
  const { t } = useTranslation();
  return (
    <BankOverviewCard
      className={className}
      url={`${urlPrefix}/fondsdepotbank`}
      rank={4}
      picture={
        <PictureContainer>
          <FondsdepotLogo width="136" height="auto" />
        </PictureContainer>
      }
      depotFeeComponent={
        <Typography variant="subtitle2" color="common.N800">
          {t('bankOverview.fondsdepotBank.depotFee')}
        </Typography>
      }
      refundPortfolioCommissionComponent={
        <Typography variant="subtitle2" color="common.N800">
          100%
        </Typography>
      }
      discountOneTimeCommissionComponent={
        <Typography variant="subtitle2" color="common.N800">
          100%
        </Typography>
      }
      averageCashbackComponent={
        <Typography variant="subtitle2" color="common.N800" fontWeight={600}>
          0.52%
        </Typography>
      }
      availableFundsComponent={
        <Typography variant="subtitle2" color="common.N800">
          7.000+
        </Typography>
      }
      savingsPlanPriceComponent={
        <Typography variant="subtitle2" color="common.N400">
          0€
        </Typography>
      }
    />
  );
};
