import { useTranslation } from 'react-i18next';

export const useImage = () => {
  const { t } = useTranslation();
  return {
    '@type': 'ImageObject',
    '@id': 'https://rentablo.de/#primaryimage',
    inLanguage: 'de-DE',
    url: 'https://rentablo.de/images/rentablo-mobile.jpg',
    width: 765,
    height: 430,
    caption: t('structuralData.headerImage.caption'),
  };
};
