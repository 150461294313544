import { ReactNode } from 'react';
import { Grid, GridProps, styled } from '@mui/material';

interface CellProps extends GridProps<any> {
  children?: ReactNode;
  isSmall?: boolean;
}

const CellContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'isSmall',
})<{ isSmall?: boolean }>(({ theme, isSmall }) => ({
  display: 'flex',
  alignItems: 'center',
  height: isSmall ? '49px' : '74px',
  borderBottom: `1px solid ${theme.palette.border?.main}`,

  [theme.breakpoints.down('sm')]: {
    height: isSmall ? '32px' : '48px',
  },
}));

export const Cell = ({ children, isSmall, ...rest }: CellProps) => {
  return (
    <CellContainer item isSmall={isSmall} {...rest}>
      {children}
    </CellContainer>
  );
};
