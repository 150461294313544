import { Box, styled, Typography, TypographyProps } from '@mui/material';
import React, { ReactNode } from 'react';
import { Trans } from 'react-i18next';

interface HeaderSmallProps extends TypographyProps {
  headerText: ReactNode;
  subHeaderText?: ReactNode;
  align?: 'left' | 'center' | 'right';
}

const MainText = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const WhiteText = styled('span')({
  color: 'white',
});

const GreyText = styled('span')(({ theme }) => ({
  color: theme.palette.grey.dark,
}));

// this is to make sure the header is visible when navigating with anchors
const Anchor = styled('span')(() => ({
  display: 'block',
  visibility: 'hidden',
  height: '100px',
  marginTop: '-100px',
}));

export const Header = ({
  headerText,
  subHeaderText,
  align,
  variant,
  id,
  ...rest
}: HeaderSmallProps) => {
  return (
    <>
      <Anchor className="anchor" id={id} />
      <Typography variant={variant} textAlign={align} {...rest}>
        <Box>
          <Trans
            transWrapTextNodes="span"
            components={{
              mainText: <MainText />,
              whiteText: <WhiteText />,
              greyText: <GreyText />,
            }}
          >
            {headerText}
          </Trans>
        </Box>
        {subHeaderText && (
          <Trans
            transWrapTextNodes="span"
            components={{
              mainText: <MainText />,
              whiteText: <WhiteText />,
              greyText: <GreyText />,
            }}
          >
            {subHeaderText}
          </Trans>
        )}
      </Typography>
    </>
  );
};
