import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { PARTNER_BANKS_BASE } from '../../../../src/helpers/urlConstants';
import DabPicture from '../images/dab.svg';
import { BankOverviewCard } from '../BankOverviewCard';

interface DabCardProps {
  className?: string;
  urlPrefix?: string;
}

const PictureContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 8.5),

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 5),
  },
}));

export const DabCard = ({ className, urlPrefix = PARTNER_BANKS_BASE }: DabCardProps) => {
  return (
    <BankOverviewCard
      className={className}
      url={`${urlPrefix}/dab`}
      rank={3}
      picture={
        <PictureContainer>
          <DabPicture width="132" height="auto" />
        </PictureContainer>
      }
      depotFeeComponent={
        <Typography variant="subtitle2" color="common.N400">
          0€
        </Typography>
      }
      refundPortfolioCommissionComponent={
        <Typography variant="subtitle2" color="common.N800">
          100%
        </Typography>
      }
      discountOneTimeCommissionComponent={
        <Typography variant="subtitle2" color="common.N800">
          100%
        </Typography>
      }
      averageCashbackComponent={
        <Typography variant="subtitle2" color="common.G500" fontWeight={600}>
          0.56%
        </Typography>
      }
      availableFundsComponent={
        <Typography variant="subtitle2" color="common.N800">
          13.000+
        </Typography>
      }
      savingsPlanPriceComponent={
        <Typography variant="subtitle2" color="common.N800">
          2€
        </Typography>
      }
    />
  );
};
