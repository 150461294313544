import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { Box, Container, styled, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SimplePicture } from '../SimplePicture';

const toSize = (val: number, factor: number) => {
  return Math.round(val * factor).toFixed(0);
};

const Title = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  textAlign: 'center',
  marginBottom: theme.spacing(4),
}));

const ImageContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  flexWrap: 'wrap',
});

export const WellKnown = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const factor = isSmall ? 0.66 : 1;
  const theme = useTheme();

  return (
    <Container data-sectionid={router.pathname === '/' ? 'well-known' : undefined}>
      <Title variant="body2" color={theme.palette.primary.main}>
        {t(`redesignMainPage.wellKnown.title`)}
      </Title>
      <ImageContainer>
        <SimplePicture
          baseImagePath="/images/testimonials/lp/WirtschaftsWoche.png"
          title={t('landingMain.testimonials.wiwo.title')}
          width="145"
          height="60"
        />
        <SimplePicture
          baseImagePath="/images/testimonials/lp/Handelsblatt.png"
          title={t('landingMain.testimonials.handelsblatt.title')}
          width={toSize(190, factor)}
          height={toSize(30, factor)}
        />
        <SimplePicture
          baseImagePath="/images/testimonials/lp/Boerse-Ard.png"
          title={t('landingMain.testimonials.ard.title')}
          width={toSize(80, factor)}
          height={toSize(50, factor)}
        />
        <SimplePicture
          baseImagePath="/images/testimonials/lp/Br.png"
          title={t('landingMain.testimonials.br.title')}
          width={toSize(70, factor)}
          height={toSize(50, factor)}
        />
        <SimplePicture
          baseImagePath="/images/testimonials/lp/Borse-Online.svg"
          title={t('landingMain.testimonials.borseonline.title')}
          width={toSize(120, factor)}
          height={toSize(55, factor)}
        />
        <SimplePicture
          baseImagePath="/images/testimonials/lp/DieWelt.png"
          title={t('landingMain.testimonials.welt.title')}
          width={toSize(180, factor)}
          height={toSize(30, factor)}
        />
        <SimplePicture
          baseImagePath="/images/testimonials/lp/Tagesschau.png"
          title={t('landingMain.testimonials.tagesschau.title')}
          width={toSize(170, factor)}
          height={toSize(30, factor)}
        />
        <SimplePicture
          baseImagePath="/images/testimonials/lp/Focus.png"
          title={t('landingMain.testimonials.focusMoney.title')}
          width={toSize(130, factor)}
          height={toSize(35, factor)}
        />
        <SimplePicture
          baseImagePath="/images/testimonials/lp/Suddeutsche.png"
          title={t('landingMain.testimonials.sueddeutsche.title')}
          width={toSize(240, factor)}
          height={toSize(30, factor)}
        />
        <SimplePicture
          baseImagePath="/images/testimonials/lp/boersede.png"
          title={t('landingMain.testimonials.boersede.title')}
          width={toSize(145, factor)}
          height={toSize(50, factor)}
        />
        <SimplePicture
          baseImagePath="/images/testimonials/lp/Capital.png"
          title={t('landingMain.testimonials.capital.title')}
          width={toSize(110, factor)}
          height={toSize(45, factor)}
        />
      </ImageContainer>
    </Container>
  );
};
