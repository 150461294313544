import { useTranslation } from 'react-i18next';

export const useWebSite = () => {
  const { t } = useTranslation();
  return {
    '@type': 'WebSite',
    '@id': 'https://rentablo.de/#website',
    url: 'https://rentablo.de/',
    name: t('structuralData.webSite.name'),
    description: t('structuralData.webSite.description'),
    publisher: { '@id': 'https://rentablo.de/#organization' },
    inLanguage: 'de-DE',
  };
};
