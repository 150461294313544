import React, { Ref, useEffect } from 'react';
import { useRouter } from 'next/router';
import * as qs from 'query-string';
import { CalculatorState } from '@rentablo/cashback-calculator';
import DetailCalculatorWithLocalStorage from '../cashbackCalculator/DetailCalculatorWithLocalStorage';
import { Fund } from '../../api/useFunds';

export interface Props {
  bookNowUrl: string;
  model?: 'flat-fee' | '80-percent';
  old?: boolean;
  onFundSelect?: (fund: Fund) => void;
  calculatorRef?: Ref<CalculatorState>;
}

export const DetailCalculatorWithUrlChange = ({
  bookNowUrl,
  model = 'flat-fee',
  calculatorRef,
  onFundSelect,
  old = false,
}: Props) => {
  const router = useRouter();
  const [defaultState, setDefaultState] = React.useState<Record<string, number> | undefined | null>(
    null
  );
  const handleChange = (state: Record<string, number>, bic: string) => {
    router.replace(
      {
        pathname: router.pathname,
        query: { state: qs.stringify(state), bic },
      },
      undefined,
      { scroll: false, shallow: true }
    );
  };

  useEffect(() => {
    if (router.isReady) {
      setDefaultState(
        router.query.state ? (qs.parse(router.query.state as string) as any) : undefined
      );
    }
  }, [router.isReady]);

  if (defaultState === null) {
    return null;
  }

  return (
    <DetailCalculatorWithLocalStorage
      bookNowUrl={bookNowUrl}
      model={model}
      defaultState={defaultState}
      defaultBic={router.query.bic as string}
      onStateChange={handleChange}
      old={old}
      onFundSelect={onFundSelect}
      calculatorRef={calculatorRef}
    />
  );
};

export default DetailCalculatorWithUrlChange;
