import { useOrganisation } from './useOrganisation';
import { useWebSite } from './useWebSite';
import { useWebPage } from './useWebPage';
import { useImage } from './useImage';

export const useWebPageGraph = (pageName: string, pageDescription: string) => {
  const organisation = useOrganisation();
  const webSite = useWebSite();
  const webPage = useWebPage(pageName, pageDescription);
  const image = useImage();
  return {
    '@context': 'https://schema.org',
    '@graph': [organisation, webSite, image, webPage],
  };
};
