import { Box, Container as MuiContainer, styled, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { getCounterNumber } from '../../../src/helpers/getCounterNumber';

const Container = styled(Box)(({ theme }) => ({
  padding: '28px 0 36px',
  backgroundColor: theme.palette.primary.main,
}));

const CountersContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  '& > div': {
    width: '50%',
  },

  [theme.breakpoints.down(1100)]: {
    '& > div': {
      width: '100%',
    },
    flexDirection: 'column',
    gap: '30px',
  },
}));

const CounterTitle = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 600,
  marginBottom: '8px',
  color: 'white',

  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    lineHeight: '24px',
  },
}));

const StyledSymbol = styled(Typography)(({ theme }) => ({
  color: 'white',
  padding: '7px 0',
  fontSize: '28px',
  lineHeight: '28px',
  borderRadius: '6px',
  border: '1.5px solid transparent',
  fontFamily: 'PT Mono',
  fontWeight: '700',
  marginLeft: '-4px',

  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
    lineHeight: '20px',
    padding: '6px 0',
  },
}));

const StyledNumber = styled(Typography)(({ theme }) => ({
  padding: '7px 8px',
  fontFamily: 'PT Mono',
  fontWeight: '700',
  fontSize: '28px',
  lineHeight: '28px',

  [theme.breakpoints.down('sm')]: {
    padding: '6px',
    fontSize: '18px',
    lineHeight: '20px',
  },
}));

const StyledNumberContainer = styled(Box)(({ theme }) => ({
  border: '1.5px solid rgba(255, 242, 235, 0.32)',
  borderRadius: '6px',
  color: 'white',
  overflow: 'hidden',
  height: '45px',
  marginRight: '4px',

  [theme.breakpoints.down('sm')]: {
    height: '33px',
  },
}));

const StyledNumberInnerContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'currentNumber',
})<{ currentNumber: number }>(({ theme, currentNumber }) => ({
  transform: `translateY(-${currentNumber * 42}px)`,
  transition: 'transform ease-in-out 0.5s',

  [theme.breakpoints.down('sm')]: {
    transform: `translateY(-${currentNumber * 32}px)`,
  },
}));

const NumberContainer = ({
  number: currentNumber,
  id,
  testId,
}: {
  number: number;
  id: string;
  testId: string;
}) => {
  const numbersList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  const [number, setNumber] = useState<number>(0);

  // this is to fix Hydration error
  useEffect(() => {
    setNumber(currentNumber);
  }, [currentNumber]);

  return (
    <StyledNumberContainer>
      <StyledNumberInnerContainer data-testid={testId} currentNumber={number}>
        {numbersList.map((number) => {
          return (
            <StyledNumber key={`${id}-${number}`} variant="body1">
              {number}
            </StyledNumber>
          );
        })}
      </StyledNumberInnerContainer>
    </StyledNumberContainer>
  );
};

const formatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 2,
});

export const formatCurrentNumber = (number: number) => {
  return formatter.format(number).replace(/\s/g, '').split('');
};

const Counter = ({ currentNumber, id }: { currentNumber: number; id: string }) => {
  return (
    <Box
      display="flex"
      sx={{
        marginLeft: '8px',
        '& > .MuiTypography-root:last-of-type': {
          marginLeft: '4px',
        },
      }}
    >
      {formatCurrentNumber(currentNumber).map((symbol, index) => {
        const isNumber = !Number.isNaN(Number(symbol));

        return isNumber ? (
          <NumberContainer
            key={`${id}-${index}`}
            id={`${id}-${index}`}
            testId={`${id}-${index}`}
            number={Number(symbol)}
          />
        ) : (
          <StyledSymbol key={`${id}-${index}`} data-testid={`${id}-${index}`} variant="body1">
            {symbol}{' '}
          </StyledSymbol>
        );
      })}
    </Box>
  );
};

// have to do it this way so that the counters won't desynchronize
const leftStartNumber = 20000000;
const rightStartNumber = 3000000;
const leftNumberStep = 0.32;
const rightNumberStep = 0.06;

export const Counters = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [leftNumber, setLeftNumber] = useState(getCounterNumber(leftStartNumber, leftNumberStep));
  const [rightNumber, setRightNumber] = useState(
    getCounterNumber(rightStartNumber, rightNumberStep)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setLeftNumber(getCounterNumber(leftStartNumber, leftNumberStep));
      setRightNumber(getCounterNumber(rightStartNumber, rightNumberStep));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Container data-sectionid="cashback-counters">
      <MuiContainer>
        <Box sx={{ [theme.breakpoints.down(1100)]: { display: 'none' } }}>
          <Typography
            fontSize="20px"
            fontWeight="600"
            lineHeight="28px"
            textAlign="center"
            mb={6}
            color="white"
          >
            {t('landingMain.counters.title')}
          </Typography>
        </Box>
        <CountersContainer>
          <Box display="flex" alignItems="center" flexDirection="column">
            <CounterTitle>{t('landingMain.counters.subtitleLeft')}</CounterTitle>
            <Counter id="left" currentNumber={leftNumber} />
          </Box>
          <Box display="flex" alignItems="center" flexDirection="column">
            <CounterTitle>{t('landingMain.counters.subtitleRight')}</CounterTitle>
            <Counter id="right" currentNumber={rightNumber} />
          </Box>
        </CountersContainer>
      </MuiContainer>
    </Container>
  );
};
