import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import { PARTNER_BANKS_BASE } from '../../../../src/helpers/urlConstants';
import MorgenFundLogo from '../images/morgen_fund_logo.svg';
import { BankOverviewCard } from '../BankOverviewCard';
import { InfoIcon } from '../../../../components/BanksComparisonTable/InfoIcon';

interface MorgenFundCardProps {
  className?: string;
  urlPrefix?: string;
}

const PictureContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 2, 0, 0),

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 5),
  },
}));

export const MorgenFundCard = ({
  className,
  urlPrefix = PARTNER_BANKS_BASE,
}: MorgenFundCardProps) => {
  const { t } = useTranslation();
  return (
    <BankOverviewCard
      className={className}
      url={`${urlPrefix}/morgenfund`}
      rank={2}
      picture={
        <PictureContainer>
          <MorgenFundLogo width="128" height="auto" />
        </PictureContainer>
      }
      depotFeeComponent={
        <Typography variant="subtitle2" color="common.N800">
          {t('bankOverview.morgenFund.depotFee')}
        </Typography>
      }
      refundPortfolioCommissionComponent={
        <Typography variant="subtitle2" color="common.N800">
          100%
        </Typography>
      }
      discountOneTimeCommissionComponent={
        <Typography variant="subtitle2" color="common.N800">
          100%
        </Typography>
      }
      averageCashbackComponent={
        <Typography variant="subtitle2" color="common.N800">
          0.44%
        </Typography>
      }
      availableFundsComponent={
        <Typography variant="subtitle2" color="common.N800">
          2.750+
        </Typography>
      }
      savingsPlanPriceComponent={
        <Typography variant="subtitle2" color="common.N400">
          0€
        </Typography>
      }
      headerTooltip={
        <Tooltip
          title={t('bankOverview.morgenFund.headerTitle')}
          placement="top"
          leaveTouchDelay={3000}
          enterTouchDelay={50}
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -10],
                },
              },
            ],
          }}
        >
          <InfoIcon variant="info" />
        </Tooltip>
      }
    />
  );
};
