import React from 'react';
import { Typography, styled, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PARTNER_BANKS_BASE } from '../../../../src/helpers/urlConstants';
import FfbPicture from '../images/ffb.svg';
import { BankOverviewCard } from '../BankOverviewCard';

interface FbbCardProps {
  className?: string;
  urlPrefix?: string;
}

const PictureContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 12),

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 6),
  },
}));

export const FbbCard = ({ className, urlPrefix = PARTNER_BANKS_BASE }: FbbCardProps) => {
  const { t } = useTranslation();
  return (
    <BankOverviewCard
      className={className}
      url={`${urlPrefix}/ffb`}
      rank={2}
      picture={
        <PictureContainer>
          <FfbPicture width="104" height="auto" />
        </PictureContainer>
      }
      depotFeeComponent={
        <Typography variant="subtitle2" color="common.N800">
          {t('bankOverview.fbb.depotFee')}
        </Typography>
      }
      refundPortfolioCommissionComponent={
        <Typography variant="subtitle2" color="common.N800">
          100%
        </Typography>
      }
      discountOneTimeCommissionComponent={
        <Typography variant="subtitle2" color="common.N800">
          100%
        </Typography>
      }
      averageCashbackComponent={
        <Typography variant="subtitle2" color="common.N800" fontWeight={600}>
          0.48%
        </Typography>
      }
      availableFundsComponent={
        <Typography variant="subtitle2" color="common.N800">
          7.000+
        </Typography>
      }
      savingsPlanPriceComponent={
        <Typography variant="subtitle2" color="common.N400">
          0€
        </Typography>
      }
    />
  );
};
