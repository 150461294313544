export const useWebPage = (name: string, description: string) => {
  return {
    '@type': 'WebPage',
    '@id': 'https://rentablo.de/#webpage',
    url: 'https://rentablo.de/',
    name,
    isPartOf: { '@id': 'https://rentablo.de/#website' },
    about: { '@id': 'https://rentablo.de/#organization' },
    primaryImageOfPage: { '@id': 'https://rentablo.de/#primaryimage' },
    description,
    inLanguage: 'de-DE',
  };
};
