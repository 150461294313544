import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { PARTNER_BANKS_BASE } from '../../../../src/helpers/urlConstants';
import { BankOverviewCard } from '../BankOverviewCard';
import FnzLogo from '../images/fnz.svg';

interface EbaseCardProps {
  className?: string;
  urlPrefix?: string;
}

const PictureContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 11),

  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 5),
  },
}));

export const EbaseCard = ({ className, urlPrefix = PARTNER_BANKS_BASE }: EbaseCardProps) => {
  return (
    <BankOverviewCard
      className={className}
      url={`${urlPrefix}/ebase`}
      rank={3}
      picture={
        <PictureContainer>
          <FnzLogo width="112" height="auto" />
        </PictureContainer>
      }
      depotFeeComponent={
        <Typography variant="subtitle2" color="common.N800">
          0€ bis 53€
        </Typography>
      }
      refundPortfolioCommissionComponent={
        <Typography variant="subtitle2" color="common.N800">
          100%
        </Typography>
      }
      discountOneTimeCommissionComponent={
        <Typography variant="subtitle2" color="common.N800">
          100%
        </Typography>
      }
      averageCashbackComponent={
        <Typography variant="subtitle2" color="common.N800" fontWeight={600}>
          0.49%
        </Typography>
      }
      availableFundsComponent={
        <Typography variant="subtitle2" color="common.N800">
          6.900+
        </Typography>
      }
      savingsPlanPriceComponent={
        <Typography variant="subtitle2" color="common.N400">
          0€
        </Typography>
      }
    />
  );
};
