import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, styled, Grid, useTheme, useMediaQuery } from '@mui/material';
import { Cell } from './Cell';

const CellContainer = styled(Grid)(({ theme }) => ({
  marginTop: '118px',

  '& .MuiGrid-item:nth-of-type(even)': {
    backgroundColor: theme.palette.background.neutralLight,
  },
  '& .MuiGrid-item:last-of-type': {
    backgroundColor: 'white',
  },

  [theme.breakpoints.down('sm')]: {
    marginTop: '74px',

    '& .MuiTypography-root': {
      fontSize: '11px',
      lineHeight: '16px',
    },
  },
  width: '165px',
}));

const CellTypography = styled(Typography)({
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '24px',
});

const CellAligned = ({ children, isSmall }: { children?: ReactNode; isSmall?: boolean }) => {
  const theme = useTheme();
  const isLessSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Cell
      justifyContent="flex-start"
      width="100%"
      sx={{
        paddingLeft: isLessSm ? theme.spacing(3) : theme.spacing(5),
        paddingRight: isLessSm ? theme.spacing(2) : theme.spacing(5),
      }}
      isSmall={isSmall}
    >
      <CellTypography color="common.N600">{children}</CellTypography>
    </Cell>
  );
};

export const DescriptionsBlock = () => {
  const { t } = useTranslation();
  return (
    <CellContainer container flexDirection="column">
      <CellAligned>{t('bankOverview.depotFee')}</CellAligned>
      <CellAligned>{t('bankOverview.refundPortfolioCommission')}</CellAligned>
      <CellAligned>{t('bankOverview.discountOneTimeCommission')}</CellAligned>
      <CellAligned>{t('bankOverview.averageCashback')}</CellAligned>
      <CellAligned>{t('bankOverview.availableFunds')}</CellAligned>
      <CellAligned>{t('bankOverview.savingsPlanPrice')}</CellAligned>
      <CellAligned isSmall>{t('bankOverview.rating')}</CellAligned>
      <CellAligned isSmall />
    </CellContainer>
  );
};
