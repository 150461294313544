export const useOrganisation = () => {
  return {
    '@type': 'Organization',
    '@id': 'https://rentablo.de/#organization',
    name: 'Rentablo - eine Marke der wealthAPI GmbH',
    url: 'https://rentablo.de/',
    sameAs: [
      'http://www.facebook.com/rentablo_gmbh',
      'https://www.linkedin.com/company/rentablo/',
      'https://twitter.com/Rentablo_GmbH',
    ],
    logo: {
      '@type': 'ImageObject',
      '@id': 'https://rentablo.de/#logo',
      inLanguage: 'de-DE',
      url: 'https://rentablo.de/images/rentablo-logo.png',
      width: 500,
      height: 182,
      caption: 'Rentablo - eine Marke der wealthAPI GmbH',
    },
    image: { '@id': 'https://rentablo.de/#logo' },
  };
};
