import { useTranslation } from 'react-i18next';

export const useFAQ = () => {
  const { t } = useTranslation();
  const questions = t('landingMain.faq.questions', { returnObjects: true }) as {
    question: string;
    answer: string;
  }[];
  const mainEntity = questions.map(({ question, answer }) => ({
    '@type': 'Question',
    name: question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: answer,
    },
  }));

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity,
  };
};
