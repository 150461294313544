import React from 'react';
import { Container as MuiContainer, styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BankOverview } from '../../redesign/BankOverview/BankOverview';
import { PARTNER_BANKS_BASE } from '../../../src/helpers/urlConstants';
import { Header } from '../../../components/Header/Header';

const Container = styled(MuiContainer)(({ theme }) => ({
  paddingTop: theme.spacing(30),
  marginBottom: theme.spacing(39),

  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(14),
  },
}));

export interface Props {
  urlPrefix?: string;
}

export const Conditions = ({ urlPrefix = PARTNER_BANKS_BASE }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Container data-sectionid="partner-bank-conditions">
      <Header
        variant="h2"
        align="center"
        headerText={t('redesignCashbackPage.conditions.title')}
        subHeaderText={t('redesignCashbackPage.conditions.subtitle')}
        sx={{
          marginBottom: theme.spacing(14),
        }}
        id="conditions"
      />

      <BankOverview urlPrefix={urlPrefix} />
    </Container>
  );
};
