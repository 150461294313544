import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
  Typography,
  StepConnector,
  Button,
  useTheme,
  Box,
  stepConnectorClasses,
} from '@mui/material';
import { CASHBACK_WIZARD_START } from '../../../../src/helpers/urlConstants';
import { Header } from '../../../../components/Header/Header';
import Document from './icons/document.svg';
import Mail from './icons/mail.svg';
import Printer from './icons/printer.svg';

const StepperStyled = styled(Stepper)(({ theme }) => ({
  background: 'transparent',
  padding: '24px',
  marginBottom: theme.spacing(11),

  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(9),
    padding: 0,
    display: 'block',

    // this is to get rid of useMediaQuery
    '& .MuiStepConnector-root': {
      padding: '0 32px',
      margin: '12px 0',
    },

    '& .MuiStep-root': {
      padding: '8px 0',
    },

    '& .MuiStepConnector-line': {
      borderLeftStyle: 'solid',
      borderTopStyle: 'none',
      minHeight: '24px',
    },
  },
}));

const StepLabelContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const StepIconContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 78,
  height: 78,
  border: theme.palette.border?.main,
  boxShadow: '0px 1.81395px 3.62791px -0.906977px rgba(30, 39, 50, 0.16)',
  borderRadius: '50%',
  color: theme.palette.tertiary.main,
  '& > svg': {
    fontSize: 44,
  },

  [theme.breakpoints.down('lg')]: {
    width: 64,
    height: 64,
    '& > svg': {
      fontSize: 36,
    },
  },
}));

const getStepIcon = (props: StepIconProps) => {
  const icons: { [index: string]: React.ReactElement } = {
    1: <Document width="44px" height="44px" />,
    2: <Printer width="44px" height="44px" />,
    3: <Mail width="44px" height="44px" />,
  };

  return <StepIconContainer>{icons[String(props.icon)]}</StepIconContainer>;
};

const Connector = styled(StepConnector)(({ theme }) => ({
  padding: theme.spacing(0, 8),
  margin: theme.spacing(3, 0),

  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey.main,

    [theme.breakpoints.down('lg')]: {
      borderLeftColor: theme.palette.grey.main,
      borderLeftWidth: 2,
    },
  },
}));

export const CashbackStepper = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Container data-sectionid="cashback-stepper">
      <Header
        variant="h2"
        align="center"
        headerText={t('redesignCashbackPage.cashbackStepper.title')}
        sx={{ marginBottom: theme.spacing(5) }}
      />
      <Box maxWidth={900} mx="auto" marginBottom={theme.spacing(11)}>
        <Typography variant="subtitle2" align="center">
          {t('redesignCashbackPage.cashbackStepper.subtitle')}
        </Typography>
      </Box>

      <StepperStyled activeStep={3} connector={<Connector />}>
        <Step>
          <StepLabel StepIconComponent={getStepIcon}>
            <StepLabelContainer>
              <Typography variant="body2" color="textSecondary">
                {t('redesignCashbackPage.cashbackStepper.steps.first.title')}
              </Typography>
              <Typography variant="body2">
                {t('redesignCashbackPage.cashbackStepper.steps.first.description')}
              </Typography>
            </StepLabelContainer>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={getStepIcon}>
            <StepLabelContainer>
              <Typography variant="body2" color="textSecondary">
                {t('redesignCashbackPage.cashbackStepper.steps.second.title')}
              </Typography>
              <Typography variant="body2">
                {t('redesignCashbackPage.cashbackStepper.steps.second.description')}
              </Typography>
            </StepLabelContainer>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={getStepIcon}>
            <StepLabelContainer>
              <Typography variant="body2" color="textSecondary">
                {t('redesignCashbackPage.cashbackStepper.steps.third.title')}
              </Typography>
              <Typography variant="body2">
                {t('redesignCashbackPage.cashbackStepper.steps.third.description')}
              </Typography>
            </StepLabelContainer>
          </StepLabel>
        </Step>
      </StepperStyled>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          marginBottom: theme.spacing(30),
          [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(13),
          },
        }}
      >
        <Button
          href={CASHBACK_WIZARD_START}
          variant="contained"
          color="primary"
          sx={{ marginBottom: theme.spacing(4), [theme.breakpoints.down('sm')]: { width: '100%' } }}
          data-eventid="book-cashback-button"
        >
          {t('redesignCashbackPage.cashbackStepper.actions.startNow')}
        </Button>
        <Typography
          variant="body1"
          color="textSecondary"
          align="center"
          sx={{
            [theme.breakpoints.down('sm')]: {
              fontSize: '12px',
              lineHeight: '18px',
            },
          }}
        >
          {t('redesignCashbackPage.cashbackStepper.actions.startNowDescription')}
        </Typography>
      </Box>
    </Container>
  );
};
