import { styled, Box, Theme, useMediaQuery } from '@mui/material';

interface PictureProps {
  baseImagePath: string;
  width?: string;
  height?: string;
  title: string;
  className?: string;
}

const Image = styled('img')({
  filter: 'grayscale(100%)',
  transition: 'filter 100ms linear',
  '&:hover': {
    filter: 'grayscale(0%)',
  },
});

export const SimplePicture = ({ baseImagePath, width, title, className, height }: PictureProps) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  return (
    <Box display="flex" alignItems="center" className={className} p={isSmall ? 2 : 3}>
      <Image
        width={width}
        height={height}
        src={`${baseImagePath}`}
        loading="lazy"
        className="lazyload"
        alt={title}
        title={title}
      />
    </Box>
  );
};
