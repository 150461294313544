import React, { useRef, useState, type MouseEvent, useEffect } from 'react';
import {
  Box,
  IconButton,
  MobileStepper,
  NoSsr,
  styled,
  Theme,
  useMediaQuery,
  Grid,
} from '@mui/material';
import AliceCarousel, { EventObject } from 'react-alice-carousel';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { PARTNER_BANKS_BASE } from '../../../src/helpers/urlConstants';
import { DescriptionsBlock } from './DescriptionsBlock';
import { ComdirectCard } from './cards/ComdirectCard';
import { DabCard } from './cards/DabCard';
import { EbaseCard } from './cards/EbaseCard';
import { FondsdepotBankCard } from './cards/FondsdepotBankCard';
import { FbbCard } from './cards/FbbCard';
import { MorgenFundCard } from './cards/MorgenFundCard';
import 'react-alice-carousel/lib/alice-carousel.css';

interface BankOverviewProps {
  urlPrefix?: string;
}

const AliceWrapper = styled(Box)({
  width: 'calc(100% - 165px)',
});

const DescriptionsBlockAligned = styled(DescriptionsBlock)({
  marginTop: '140px',
});

const Space = styled(Box)<{ activeIndex: number; totalSlides: number; slidesInView: number }>(
  ({ theme, activeIndex, totalSlides, slidesInView }) => ({
    position: 'absolute',
    top: '59px',
    left: '50%',
    width: `${slidesInView * 12 - 6}px`,
    height: '6px',
    borderRadius: 12,
    background: theme.palette.tertiary.main,
    transform: `translateX(-50%) translateX(-${(totalSlides - slidesInView) * 6}px) translateX(${
      activeIndex * 12
    }px)`,
    transition: 'transform ease-out 0.3s',
  })
);

const IconButtonStyled = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isDisabled',
})<{ isDisabled?: boolean }>(({ theme, isDisabled }) => ({
  width: 36,
  height: 36,
  border: `1px solid ${isDisabled ? theme.palette.border?.light : theme.palette.border?.main}`,
  borderRadius: 24,
  cursor: isDisabled ? 'default' : 'pointer',

  '& svg': {
    color: isDisabled ? theme.palette.grey[200] : theme.palette.grey[500],
  },
}));

const MobileStepperStyled = styled(MobileStepper)(({ theme }) => ({
  marginTop: theme.spacing(9),

  '& .MuiMobileStepper-dot': {
    width: 6,
    height: 6,
    background: theme.palette.grey[200],
    margin: '0 3px',
  },
}));

const responsive = {
  0: { items: 1, dots: 6 },
  500: { items: 2, dots: 6 },
  600: { items: 3, dots: 4 },
  850: { items: 4, dots: 3 },
  1100: { items: 5, dots: 2 },
  1200: { items: 6, dots: 0 },
};

export const BankOverview = ({ urlPrefix = PARTNER_BANKS_BASE }: BankOverviewProps) => {
  const carouselRef = useRef<AliceCarousel>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const is6 = useMediaQuery((theme: Theme) => theme.breakpoints.up(1200));
  const is5 = useMediaQuery((theme: Theme) => theme.breakpoints.up(1100));
  const is4 = useMediaQuery((theme: Theme) => theme.breakpoints.up(850));
  const is3 = useMediaQuery((theme: Theme) => theme.breakpoints.up(600));
  const is2 = useMediaQuery((theme: Theme) => theme.breakpoints.up(500));
  const slidesInView = is6 ? 6 : is5 ? 5 : is4 ? 4 : is3 ? 3 : is2 ? 2 : 1;

  const syncActiveIndex = ({ item }: EventObject) => {
    setActiveIndex(item);
  };

  useEffect(() => {
    const handleResize = () => {
      if (activeIndex > slidesAmount - slidesInView - 1) {
        setActiveIndex(slidesAmount - slidesInView);
      }
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const cardsColumns = [
    <ComdirectCard key="comdirect" urlPrefix={urlPrefix} className="item" data-value="1" />,
    <DabCard key="dab" urlPrefix={urlPrefix} className="item" data-value="2" />,
    <EbaseCard key="ebase" urlPrefix={urlPrefix} className="item" data-value="3" />,
    <FondsdepotBankCard key="fondsdepot" urlPrefix={urlPrefix} className="item" data-value="4" />,
    <FbbCard key="fbb" urlPrefix={urlPrefix} className="item" data-value="5" />,
    <MorgenFundCard key="morgenfund" urlPrefix={urlPrefix} className="item" data-value="6" />,
  ];
  const slidesAmount = cardsColumns.length;
  const isLastSlide = activeIndex === slidesAmount - slidesInView;
  const isFirstSlide = activeIndex === 0;

  const handleClickPrevSlide = (e: MouseEvent) => {
    if (!isFirstSlide) {
      setActiveIndex(activeIndex - 1);
      carouselRef?.current?.slidePrev(e);
    }
  };
  const handleClickNextSlide = (e: MouseEvent) => {
    if (!isLastSlide) {
      setActiveIndex(activeIndex + 1);
      carouselRef?.current?.slideNext(e);
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex">
        <DescriptionsBlockAligned />
        <NoSsr>
          <AliceWrapper>
            <AliceCarousel
              ref={carouselRef}
              items={cardsColumns}
              activeIndex={activeIndex}
              responsive={responsive}
              disableButtonsControls
              disableDotsControls
              onSlideChanged={syncActiveIndex}
            />
          </AliceWrapper>
        </NoSsr>
      </Box>
      {slidesInView < slidesAmount && (
        <Box position="relative">
          <Space activeIndex={activeIndex} slidesInView={slidesInView} totalSlides={slidesAmount} />
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>
              <MobileStepperStyled
                variant="dots"
                position="static"
                steps={6}
                backButton={
                  <IconButtonStyled onClick={handleClickPrevSlide} isDisabled={isFirstSlide}>
                    <ChevronLeftRoundedIcon />
                  </IconButtonStyled>
                }
                nextButton={
                  <IconButtonStyled onClick={handleClickNextSlide} isDisabled={isLastSlide}>
                    <ChevronRightRoundedIcon />
                  </IconButtonStyled>
                }
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};
